import ApiService, { HTTPMethod } from './api.core'
import { buildQueryParamsUrl, KPretty } from '../services/utils'
import { Transformer } from '../models/Transformer'
import dayjs from 'dayjs'

class CoveragesApi {
  printVehicleCoverages(vehicleCoveragePrices) {
    let totalPrice = 0
    for (let vehIndex in vehicleCoveragePrices) {
      let veh = vehicleCoveragePrices[vehIndex]
      console.log('\tVehicle', vehIndex)
      for (let covKey in veh.coveragePrices) {
        let cov = veh.coveragePrices[covKey]
        console.log('\t\tCoverage: ', covKey, 'Annual Price: ', cov.annualPrice)
        totalPrice += cov.annualPrice
        if (cov.factorNameToValue) {
          let factors = ''
          for (let factorKey in cov.factorNameToValue) {
            factors += `${factorKey}: ${cov.factorNameToValue[factorKey]}, `
          }
          console.log('\t\tFactors: ', factors)
        }
      }
    }
    console.log('\tTotal annual cost: ', totalPrice)
  }

  async loadPlans() {
    const now = new Date()
    const start_time = dayjs(now).format()

    let res = await ApiService.fetch('coverages/plans', HTTPMethod.POST, { start_time })
    if (res.ok) {
      res.parsedData = Transformer.initializeFromDataDeep({}, res.data)
      for (let planIndex in res.parsedData.plans) {
        let plan = res.parsedData.plans[planIndex]
        console.log(plan.label)
        this.printVehicleCoverages(plan.vehicleCoveragePrices)
      }
    }
    return res
  }

  async loadOptions() {
    let res = await ApiService.fetch('coverages/options', HTTPMethod.GET)

    if (res.ok) {
      Object.keys(res.data.options).forEach(key => {
        res.data.options[key].map(item => {
          if (item.breakdown) {
            const liabilityBreakdownOrder = ['Per Person', 'Per Accident', 'Property Damage', ' Property']
            item.breakdown
              .sort((a, b) => (liabilityBreakdownOrder.indexOf(a.label) < liabilityBreakdownOrder.indexOf(b.label) ? -1 : 1))
              .map(k => {
                if (Number.isNaN(Number.parseInt(k.sublabel))) return k
                else {
                  k.sublabel = KPretty(k.sublabel)
                  return k
                }
              })
          }
          if (item.subtitle) {
            if (Number.isNaN(Number.parseInt(item.subtitle))) {
              if (item.subtitle == 'true') item.subtitle = 'Included'
              if (item.subtitle == 'false') item.subtitle = 'Not Included'
            } else {
              item.subtitle = KPretty(item.subtitle)
            }
          }
          return item
        })
      })
    }
    return res
  }

  async requestOffer(configuration) {
    const nowString = dayjs(new Date()).format()
    if (!configuration.start_time) {
      configuration.start_time = nowString
    } else {
      const nowComp = new Date().setHours(0, 0, 0, 0)
      const selectionComp = new Date(configuration.start_time.setHours(0, 0, 0, 0))
      if (nowComp > selectionComp) {
        configuration.start_time = nowString
      } else {
        const now = new Date()
        configuration.start_time.setMinutes(now.getMinutes())
        configuration.start_time.setHours(now.getHours())
        configuration.start_time = dayjs(configuration.start_time).format()
      }
    }

    let res = await ApiService.fetch('coverages/offer', HTTPMethod.POST, configuration)
    if (res.ok) {
      res.data = Transformer.initializeFromDataDeep({}, res.data)
      this.printVehicleCoverages(res.data.vehicleCoveragePrices)
    }
    return res
  }

  async getOfferById(offerId) {
    let res = await ApiService.fetch(`coverages/get_offer/${offerId}`, HTTPMethod.GET)
    if (res.ok && res.data != null) {
      res.data = Transformer.initializeFromDataDeep({}, res.data)
    }
    return res
  }

  async createDigitalSignatureDocs(offerId, optionsViolationsReqSignIds) {
    let res = await ApiService.fetch('coverages/createDigitalSignatureDocs', HTTPMethod.POST, {
      offer_id: offerId,
      options_violations_req_sign_ids: optionsViolationsReqSignIds,
    })
    return res
  }

  async saveDigitalSignature(signature, applicationId) {
    let res = await ApiService.fetch('coverages/saveDigitalSignature', HTTPMethod.POST, {
      signature: signature,
      application_id: applicationId,
    })
    return res
  }

  async sendDigitalSignatureSigningForm() {
    let res = await ApiService.fetch('coverages/sendDigitalSignatureSigningForm', HTTPMethod.POST, {})
    return res
  }

  async sendDigitalSignatureSigningFormByInsurance(insuranceId) {
    let res = await ApiService.fetch(`coverages/sendDigitalSignatureSigningForm/insurance/${insuranceId}`, HTTPMethod.POST, {})
    return res
  }

  async getOnlineRequiredSignatureFormsPDF(applicationId) {
    return await ApiService.fetch(`coverages/application/${applicationId}/getOnlineDocsForSignin`, HTTPMethod.POST, {})
  }

  async bindOffer(offerId, signature, optionsViolationsReqSignIds, externalId, isMobile) {
    let res = await ApiService.fetch('coverages/bind', HTTPMethod.POST, {
      offer_id: offerId,
      signature: signature,
      options_violations_req_sign_ids: optionsViolationsReqSignIds,
      external_user_id: externalId,
      is_mobile: isMobile,
    })
    return res
  }

  async bindMidtermOffer(offerId, insuranceId) {
    let res = await ApiService.fetch('coverages/midtermBind', HTTPMethod.POST, {
      offer_id: offerId,
      insurance_id: insuranceId,
    })
    return res
  }

  async hackSavePlan(offerId) {
    let res = await ApiService.fetch(`coverages/selectPlan?planId=${offerId}`, HTTPMethod.POST, {
      plan_id: offerId,
    })
    return res
  }

  async getQuotePDF(offerId) {
    let res = await ApiService.fetch(`coverages/downloadQuotePdf?offerId=${offerId}`, HTTPMethod.GET)
    return res
  }

  async validateCoverages(request) {
    return await ApiService.fetch('coverages/validate_policy_coverages', HTTPMethod.POST, request)
  }
}

export default new CoveragesApi()
