import React, { useReducer, useEffect, useRef } from 'react'
import { EventEmitterService, EventKey } from '../services/EventEmitterService'
import { SessionService } from '../services/SessionService'
import {
  awaitWithLoading,
  isInAccountUpdateFlow,
  isInIssuancePage,
  isInPolicyManagmentFlow,
  isInPurchaseFlow,
  isToday,
} from '../services/utils'
import StorageService from '../services/StorageService'
import { COVERAGE_TYPES } from '../models/insurance-models/CoverageType'
import coveragesApi from '../api/coverages.api'
import { merge } from 'merge-anything'
import { SessionContextStore } from './SessionContext'
import { ActiveInsuranceModel } from '../models/insurance-models/ActiveInsuranceModel'
import { useHistory, useLocation } from 'react-router-dom'
import { UserApi } from '../api/user.api'
import { enhancedCoversionEvent } from '../components/Core/Utilities/GTagManger'
import { BillingPeriod } from '../models/insurance-models/InsuranceBillingPeriodModel'
import { parseOfferViolations } from '../utilities/ParseOfferViolations'
import { StatesContextStore } from './StatesContext'
import { createOfferCart } from '../components/Insurance/PlanSummaryMobile/utils'
import _ from 'lodash'
import { statesWithDefaultBillingMonthly, statesWithLiabilityTripleBundle } from '../Constants'

export const OFFERS_HISTORY_KEY = 'offers_ongoing_purchase'

export const methodsToFlags = {
  'Apple pay': 'applePay',
  'Google pay': 'googlePay',
  'Credit/Debit': 'credit',
  Bank: 'bank',
}

const OFFERS_ACTIOS = {
  ADD_OPERATOR: 'ADD_OPERATOR',
  DELETE_OPERATOR: 'DELETE_OPERATOR',
  EDIT_OPERATOR: 'EDIT_OPERATOR',
  SET_SPECIAL_PRICE: 'SET_SPECIAL_PRICE',
  SET_INDOOR_COVERAGE: 'SET_INDOOR_COVERAGE',
}

let liabilityCoverages = ['bodily_injury_liability', 'passenger_liability', 'property_damage']

const isLiabilityBundleConfigurationAvailable = data => {
  return data && liabilityCoverages.every(q => data[q] && data[q][0] && data[q][0].bundle_key && data[q][0].bundle_option_key)
}

const bundleLiabilityCoverageInfoIntoBodilyLiability = data => {
  if (isLiabilityBundleConfigurationAvailable(data)) {
    for (let bodilyInjuryLiabilityOption of data['bodily_injury_liability']) {
      let propertyDamageLiabilityOption = data['property_damage'].find(
        option => option.bundle_option_key == bodilyInjuryLiabilityOption.bundle_option_key
      )

      bodilyInjuryLiabilityOption['breakdown'] = [
        ...bodilyInjuryLiabilityOption['breakdown'],
        { label: propertyDamageLiabilityOption['breakdown'][0].label, sublabel: propertyDamageLiabilityOption['breakdown'][0].sublabel },
      ]
    }
  }
}

export const unbundleBodilyLiabilityToOtherLiabilityCoverages = (offer, options) => {
  if (liabilityCoverages.every(coverageName => coverageName in offer)) {
    let bodilyInjuryLiabilityOptionGuid = offer['bodily_injury_liability']
    let bodilyInjuryLiabilityOptionBundleId = options['bodily_injury_liability'].find(
      option => option.value == bodilyInjuryLiabilityOptionGuid
    ).bundle_option_key
    let matchingPropertyDamageOption = options['property_damage'].find(
      option => option.bundle_option_key == bodilyInjuryLiabilityOptionBundleId
    )
    let matchingPassengerLiabilityOption = options['passenger_liability'].find(
      option => option.bundle_option_key == bodilyInjuryLiabilityOptionBundleId
    )
    offer['property_damage'] = matchingPropertyDamageOption.value
    offer['passenger_liability'] = matchingPassengerLiabilityOption.value
  }
}

export const unbundleBodilyLiabilityToOtherLiabilityCoveragesNewFlow = (offer, options) => {
  if (liabilityCoverages.every(coverageName => coverageName in offer)) {
    let bodilyInjuryPropertyDamageOptionGuid = offer['bodily_injury_property_damage']
    let bodilyInjuryPropertyDamageOptionBundleId = options['bodily_injury_property_damage'].find(
      option => option.value == bodilyInjuryPropertyDamageOptionGuid
    ).bundle_option_key
    let matchingPassengerLiabilityOption = options['passenger_liability'].find(
      option => option.bundle_option_key == bodilyInjuryPropertyDamageOptionBundleId
    )
    offer['passenger_liability'] = matchingPassengerLiabilityOption.value
  }
}

const SCHEMA_VERSION = '1.0.4'

const defaultState = {
  schemaVersion: SCHEMA_VERSION,
  offerId: '',
  maximumUasInAir: 1,
  additionalFees: 0,
  minimumEarnedPremium: 0,
  monthlyOffers: [],
  annualOffers: [],
  signature: '',
  operators: [],
  options: null,
  optionsViolationsSignatureRequired: {},
  offerCart: {},
  offerCardErrors: {},
  offerCardWarnings: {
    // warnings: [],
    violationsId: [],
  },
  plans: {
    items: [],
    status: 'idle',
    selectedPlan: null,
    shouldLoadPlans: false,
  },
  pricebreakdown: {},
  subscriptionStartTime: new Date(),
  specialPrice: { enabled: 'no', custom_premium: 0, custom_premium_token: '' },
  activeInsurance: new ActiveInsuranceModel(),
  changeStartingDate: date => {
    throw 'Not Implemented Exception'
  },
  loadQuote: quote => {
    throw 'Not implemented'
  },
  shouldPassSummary: false,
  shouldPassIssuance: false,
  shouldPassCreditCard: false,
  paymentRequest: null,
  paymentMethod: 'Credit/Debit',
  vehiclesDetailes: [],
  clientSecret: null,
  isBankLinked: false,
  is_scheduled_policy: false,
  billingPeriod: BillingPeriod.MONTH,
  billingPeriodDiscount: { ThreeMonths: 16, SixMonths: 20, Annual: 22 },
  isCustomizedCart: false,
  billingFlowType: '',
}

const calculateState = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'OFFERS_LOADED':
      return { ...state, monthlyOffers: action.monthlyOffers, annualOffers: action.annualOffers }
    case 'OPTIONS_LOADED':
      return { ...state, options: action.data }
    case 'OPTIONS_REQUIRE_SIGN_LOADED':
      return { ...state, optionsViolationsSignatureRequired: action.data }
    case 'SAVE_SIGNATURE':
      return { ...state, signature: action.data }
    case 'SET_CART_ERRORS':
      return { ...state, offerCardErrors: action.data }
    case 'SET_CART_WARNINGS':
      return { ...state, offerCardWarnings: action.data }
    case 'CART_CHANGED': {
      let { newOffer, administrativeDivision } = action.data
      if (statesWithLiabilityTripleBundle.includes(administrativeDivision)) {
        unbundleBodilyLiabilityToOtherLiabilityCoverages(newOffer, state.options)
      } else {
        unbundleBodilyLiabilityToOtherLiabilityCoveragesNewFlow(newOffer, state.options)
      }
      return { ...state, offerCart: newOffer }
    }
    case 'ACTIVE_INSURANCE_CHANGED':
      return { ...state, activeInsurance: action.data }
    case 'PRICE_BREAKDOWN_CHANGED':
      return {
        ...state,
        pricebreakdown: action.data,
        offerId: action.data.offerId,
        offerCardErrors: {},
        vehiclesDetailes: action.data.vehiclesDetailsForUi,
      }
    case 'CHANGE_STARTING_DATE':
      if (isToday(action.startingDate)) {
        return { ...state, subscriptionStartTime: new Date() }
      } else if (action.startingDate && action.startingDate > new Date() && !isToday(action.startingDate))
        return { ...state, subscriptionStartTime: action.startingDate }
      else return state
    case 'LOAD_QUOTE': {
      let startingDate = new Date()
      if (action.data.start_time) {
        let requestedStartingTime = new Date(action.data.start_time)
        if (requestedStartingTime > new Date() && !isToday(requestedStartingTime)) startingDate = requestedStartingTime
      }
      return {
        ...state,
        subscriptionStartTime: startingDate,
        maximumUasInAir: action.data.maximumUasInAir || action.data.maximum_uas_in_air || 1,
        coverageType: action.data.coverage_type || action.data.coverageType || COVERAGE_TYPES.COMBINED,
        operators: action.data.operators || action.data.operators || [],
        worldWideCoverage: {
          territorialLimits: action.data.territorial_limits,
          territorialLimitsDescription: action.data.territorial_limits_description,
        },
        personalInjuryCoverage: Number.parseInt(action.data.personal_injury_limit),
        medicalExpense: Number.parseInt(action.data.medical_expense_limit),
        customPremium: Number.parseInt(action.data.custom_premium),
        indoorCoverage: { included: action.data.indoor_coverage, description: action.data.indoor_coverage_description },
        customPremiumToken: action.data.custom_premium_token,
      }
    }
    case OFFERS_ACTIOS.SET_SPECIAL_PRICE:
      return { ...state, specialPrice: action.data }
    case OFFERS_ACTIOS.ADD_OPERATOR:
      return { ...state, operators: [...state.operators, action.data] }
    case OFFERS_ACTIOS.EDIT_OPERATOR:
      return { ...state, operators: state.operators.map((item, index) => (index === action.data.index ? action.data.operator : item)) }
    case OFFERS_ACTIOS.DELETE_OPERATOR:
      return { ...state, operators: state.operators.filter((item, index) => index != action.data) }
    case 'LOAD_PLANS_STARTED':
      return { ...state, plans: { ...state.plans, status: 'busy' } }
    case 'LOAD_PLANS_SUCCESS':
      return { ...state, plans: { ...state.plans, items: action.data, status: 'idle' } }
    case 'LOAD_PLANS_ERROR':
      return { ...state, plans: { ...state.plans, status: 'idle' } }
    case 'SELECT_PLAN':
      return { ...state, plans: { ...state.plans, selectedPlan: action.data } }
    case 'PASS_SUMMARY':
      return { ...state, shouldPassSummary: action.data }
    case 'PASS_ISSUANCE':
      return { ...state, shouldPassIssuance: action.data }
    case 'SET_BILLING_PERIOD':
      return { ...state, billingPeriod: action.data }
    case 'SET_BILLING_PERIOD_ENABLED':
      return { ...state, billingPeriodEnabled: action.data }
    case 'PASS_CREDIT_CARD':
      return { ...state, shouldPassCreditCard: action.data }
    case 'SET_PAYMENT_REQUEST':
      return { ...state, paymentRequest: action.data }
    case 'SET_PAYMENT_METHOD':
      return { ...state, paymentMethod: action.data }
    case 'SET_CLIENT_SECRET':
      return { ...state, clientSecret: action.data }
    case 'LINKED_BANK_ACCOUNT': {
      return { ...state, isBankLinked: action.data }
    }
    case 'POST_PURCHASE': {
      return { ...state, signature: undefined, is_scheduled_policy: action.data.is_scheduled }
    }
    case 'LOAD_PLANS_AFTER_MVR':
      return { ...state, plans: { ...state.plans, shouldLoadPlans: action.data } }
    case 'SET_BILLING_PERIOD_DISCOUNTS': {
      return { ...state, billingPeriodDiscount: action.data }
    }
    case 'SET_IS_CUSTOMIZED_CART':
      return { ...state, isCustomizedCart: action.data }
    case 'UPDATE_BILLING_FLOW_TYPE':
      return { ...state, billingFlowType: action.data }
    case 'RESET_OFFER_CART':
      return { ...state, offerCart: {} }
    default:
      return state
  }
}

const stateToLocalStorage = state => {
  const { offerCardErrors, ...rest } = state
  StorageService.setItem(OFFERS_HISTORY_KEY, JSON.stringify(rest))
}

const reducer = (state, action) => {
  const newState = calculateState(state, action)
  stateToLocalStorage(newState)
  return newState
}

export const BuyingInsuranceContextStore = React.createContext(defaultState)
BuyingInsuranceContextStore.displayName = 'Buying Insurance ContextStore'

const BuyingInsuranceContext = props => {
  const initialRender = useRef(true)
  const sessionContext = React.useContext(SessionContextStore)
  const statesContext = React.useContext(StatesContextStore)

  const [state, dispatch] = useReducer(reducer, defaultState, () => {
    let savedState = {}
    // Initialize from local storage
    if (StorageService.getItem(OFFERS_HISTORY_KEY)) {
      savedState = JSON.parse(StorageService.getItem(OFFERS_HISTORY_KEY))
      savedState.subscriptionStartTime = new Date(savedState.subscriptionStartTime)
      // if (savedState.subscriptionStartTime < new Date() || !savedState.subscriptionStartTime) {
      //   savedState.subscriptionStartTime = new Date()
      // }
    }
    // check context state version.
    // if there is a discrepency, ignore saved state
    const res = SCHEMA_VERSION.localeCompare(savedState.schemaVersion, undefined, { numeric: true, sensitivity: 'base' })
    if (res !== 0) {
      return defaultState
    }

    return merge(defaultState, savedState)
  })

  useEffect(() => {
    if (sessionContext.user.state && SessionService.isLoggedIn()) {
      const check = async () => {
        let isPeriodicPaymentEnabled = (await UserApi.isPeriodicPaymentEnabled()).parsedData
        dispatch({ type: 'SET_BILLING_PERIOD_ENABLED', data: isPeriodicPaymentEnabled })
        if (
          isPeriodicPaymentEnabled &&
          sessionContext.user &&
          (sessionContext.activeInsurance == {} || sessionContext.activeInsurance == null)
        ) {
          const billingPeriod = statesWithDefaultBillingMonthly.includes(sessionContext.user.state)
            ? BillingPeriod.MONTH
            : BillingPeriod.THREEMONTHS
          setBillingPeriod(billingPeriod)
        } else if (sessionContext.activeInsurance && sessionContext.activeInsurance != {} && sessionContext.activeInsurance.billingPeriod) {
          setBillingPeriod(sessionContext.activeInsurance.billingPeriod)
        } else {
          setBillingPeriod(BillingPeriod.MONTH)
        }
      }

      check().catch(console.error)
    }
  }, [sessionContext.user.state])

  useEffect(() => {
    if (Object.keys(statesContext.billing_flow_type_states).length > 0 && sessionContext.user.state)
      dispatch({ type: 'UPDATE_BILLING_FLOW_TYPE', data: statesContext.billing_flow_type_states[sessionContext.user.state] })
  }, [sessionContext.user.state, statesContext.billing_flow_type_states])

  const location = useLocation()
  const history = useHistory()

  liabilityCoverages = statesWithLiabilityTripleBundle.includes(sessionContext.user.administrativeDivision)
    ? ['bodily_injury_liability', 'passenger_liability', 'property_damage']
    : ['bodily_injury_property_damage', 'passenger_liability']

  const requestOffer = async () => {
    if (sessionContext.user.userInsuredPersons.length > 0) {
      if (defaultState == state) return
      if (state.offerCart.vehicle_requests == undefined || Object.keys(state.offerCart.vehicle_requests).length === 0) return

      let startTime = state.subscriptionStartTime
      if (startTime && isToday(startTime)) startTime = undefined
      else {
        let dateObj = new Date(startTime)
        startTime = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 1, 0, 0))
      }

      const { vehicle_requests, ...offer } = state.offerCart

      let billingPeriod = state.billingPeriod
      if (billingPeriod == undefined && sessionContext.activeInsurance != null) {
        billingPeriod = sessionContext.activeInsurance.billingPeriod
      } else if (billingPeriod == undefined) {
        setBillingPeriod(BillingPeriod.MONTH)
      }

      const coverages = {}
      for (const [key, value] of Object.entries(offer)) {
        if (state.options[key]) {
          coverages[key] = {
            option_id: value,
            is_stackable: state.options[key].is_stackable,
          }
        }
      }

      for (const [key1, value1] of Object.entries(state.offerCart.vehicle_requests)) {
        for (const [key2, value2] of Object.entries(state.offerCart.vehicle_requests[key1].coverages)) {
          if (state.options[key2]) {
            state.offerCart.vehicle_requests[key1].coverages[key2] = value2
          }
        }
      }
      let res
      try {
        res = await awaitWithLoading(
          coveragesApi.requestOffer({
            start_time: startTime,
            coverages,
            vehicle_requests: state.offerCart.vehicle_requests,
            billing_period: billingPeriod,
          })
        )
      } catch (e) {
        StorageService.removeItem(OFFERS_HISTORY_KEY)
        window.location.reload()
      }

      dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: res.data })
      if (res.ok) {
        dispatch({ type: 'SET_BILLING_PERIOD_DISCOUNTS', data: res.data.periodicDiscounts })
      }
      if (res.data && res.data.code == 400) {
        let err = parseOfferViolations(res.data)
        dispatch({ type: 'SET_CART_ERRORS', data: err })
      }
    }
  }

  const getOfferById = async (offerId, route) => {
    const res = await awaitWithLoading(coveragesApi.getOfferById(offerId))
    res.data != null && dispatch({ type: 'PRICE_BREAKDOWN_CHANGED', data: res.data })
    if (res.ok) {
      setBillingPeriod(res.data.billingPeriod)
      changeStartingDate(res.data.startDate)
      if (res.data === null) {
        if (sessionContext.user.userVehicles.some(vehicle => vehicle.lienholder === 'yes')) {
          choosePlan(state.plans.items[1])
          dispatch({ type: 'SELECT_PLAN', data: 1 })
        } else {
          choosePlan(state.plans.items[0])
          dispatch({ type: 'SELECT_PLAN', data: 0 })
        }
      } else {
        const newOffer = createOfferCart(res.data.vehicleCoveragePrices)
        const isCustomized = await checkIfCustomizedPlan(newOffer)

        dispatch({
          type: 'CART_CHANGED',
          data: {
            newOffer: newOffer,
            isNewFlow: sessionContext.newPaymentFlow,
            administrativeDivision: sessionContext.user.administrativeDivision,
          },
        })
        dispatch({ type: 'SET_BILLING_PERIOD_DISCOUNTS', data: res.data.periodicDiscounts })
        dispatch({ type: 'SET_IS_CUSTOMIZED_CART', data: isCustomized })
      }
    } else if (res.data && res.data.code == 400) {
      let err = parseOfferViolations(res.data)
      dispatch({ type: 'SET_CART_ERRORS', data: err })
    } else {
      if (sessionContext.user.userVehicles.some(vehicle => vehicle.lienholder === 'yes')) {
        choosePlan(state.plans.items[1])
        dispatch({ type: 'SELECT_PLAN', data: 1 })
      } else {
        choosePlan(state.plans.items[0])
        dispatch({ type: 'SELECT_PLAN', data: 0 })
      }
    }
    if (!route) {
      route = '/insurance/summary'
    }
    history.push(route)
  }

  const checkIfCustomizedPlan = async newOffer => {
    const cartBasicPlan = createOfferCart(state.plans.items[0].vehicleCoveragePrices)
    const cartPoplarPlan = createOfferCart(state.plans.items[1].vehicleCoveragePrices)
    const carExtendedPlan = createOfferCart(state.plans.items[2].vehicleCoveragePrices)

    const carts = [cartBasicPlan, cartPoplarPlan, carExtendedPlan]
    let isCustomized = !carts.some(cart => _.isEqual(cart, newOffer))

    return isCustomized
  }

  const loadPlans = async () => {
    if (!isInPolicyManagmentFlow(location.pathname) && !isInAccountUpdateFlow(location.pathname)) {
      dispatch({ type: 'LOAD_PLANS_STARTED' })
      const res = await awaitWithLoading(coveragesApi.loadPlans())
      if (res.ok) {
        const needOvreidePlanAndCustomize = !isInIssuancePage(location.pathname)
        if (needOvreidePlanAndCustomize) {
          dispatch({ type: 'SELECT_PLAN', data: null })
          dispatch({ type: 'SET_IS_CUSTOMIZED_CART', data: false })
        }

        dispatch({ type: 'LOAD_PLANS_SUCCESS', data: res.parsedData.plans })
      } else {
        dispatch({ type: 'SELECT_PLAN', data: null })
        dispatch({ type: 'LOAD_PLANS_ERROR' })
        EventEmitterService.dispatch(EventKey.ShowError, res)
      }
    }
  }

  const loadFormOptions = async () => {
    let res = await coveragesApi.loadOptions()
    if (res.ok) {
      let parsedOptions = {}
      bundleLiabilityCoverageInfoIntoBodilyLiability(res.data.options)
      Object.keys(res.data.options).map(name => {
        parsedOptions[name] = res.data.options[name].map(item => {
          if (item.breakdown && item.breakdown.length > 1)
            return {
              labels: item.breakdown.map(k => k.label),
              values: item.breakdown.map(k => k.sublabel),
              value: item.value,
              tooltip: item.tooltip,
              bundle_option_key: item.bundle_option_key,
              bundle_key: item.bundle_key,
              name: item.name,
              is_stackable: item.is_stackable,
              type: item.type,
            }
          else if (item.breakdown)
            return {
              label: item.breakdown[0].sublabel,
              value: item.value,
              tooltip: item.tooltip,
              bundle_option_key: item.bundle_option_key,
              bundle_key: item.bundle_key,
              name: item.name,
              is_stackable: item.is_stackable,
              type: item.type,
            }
          else {
            return {
              label: item.subtitle,
              value: item.value,
              tooltip: item.tooltip,
              bundle_option_key: item.bundle_option_key,
              bundle_key: item.bundle_key,
              name: item.name,
              is_stackable: item.is_stackable,
              type: item.type,
            }
          }
        })
      })
      let parsedOptionsViolationsSignatureRequired = res.data.violations_requiring_signing

      dispatch({ type: 'OPTIONS_LOADED', data: parsedOptions })
      dispatch({ type: 'OPTIONS_REQUIRE_SIGN_LOADED', data: parsedOptionsViolationsSignatureRequired })
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
  }

  const checkCoveragesViolations = offerCart => {
    let coveragesSelectionsToWarning = {
      violationsId: [],
    }

    let optionsViolationsSignatureRequired = state.optionsViolationsSignatureRequired
    if (Object.keys(optionsViolationsSignatureRequired).length === 0) {
      return coveragesSelectionsToWarning
    }

    let userVehiclesReq = offerCart.vehicle_requests
    if (userVehiclesReq == null) {
      return
    }
    let userVehicles = Object.keys(userVehiclesReq).map(key => {
      return userVehiclesReq[key]
    })

    optionsViolationsSignatureRequired.forEach(element => {
      let firstCoverageName = element.coverage_name1
      let secondCoverageName = element.coverage_name2

      if (
        offerCart[firstCoverageName] === element['coverage_option_id1'] &&
        offerCart[secondCoverageName] === element['coverage_option_id2']
      ) {
        coveragesSelectionsToWarning.violationsId.push(element.coverage_option_violation_with_signing_id)
        coveragesSelectionsToWarning[firstCoverageName] = element['signing_text']
      }

      if (sessionContext.user.state == 'IN') {
        userVehicles.forEach(x => {
          if (
            offerCart[firstCoverageName] === element['coverage_option_id1'] &&
            x.coverages[secondCoverageName] === element['coverage_option_id2']
          ) {
            coveragesSelectionsToWarning.violationsId.push(element.coverage_option_violation_with_signing_id)
            coveragesSelectionsToWarning[firstCoverageName] = element['signing_text']
          }
        })
      }
    })
    return coveragesSelectionsToWarning
  }

  const resetOfferCart = () => {
    dispatch({ type: 'RESET_OFFER_CART' })
  }

  // calculating coverages violations
  useEffect(() => {
    const warnings = checkCoveragesViolations(state.offerCart)
    warnings !== undefined && dispatch({ type: 'SET_CART_WARNINGS', data: warnings })
  }, [state.offerCart, state.optionsViolationsSignatureRequired, sessionContext.user])

  useEffect(() => {
    SessionService.isLoggedIn() &&
      sessionContext.user &&
      sessionContext.user.userInsuredPersons.length > 0 &&
      sessionContext.user.userVehicles.every(x => !x.new_vehicle_req_details) &&
      !sessionContext.doOrphanVehiclesExist() &&
      loadFormOptions()
  }, [sessionContext.user])

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      sessionContext.user.userInsuredPersons.length > 0 &&
        sessionContext.user.userVehicles.every(x => !x.new_vehicle_req_details && x.model && x.make) &&
        SessionService.isLoggedIn() &&
        !sessionContext.doOrphanVehiclesExist() &&
        loadPlans()
    }

    return function cleanup() {
      //TODO: Deleting the local storage only for broker (?)
      if (SessionService.isBroker()) StorageService.removeItem(OFFERS_HISTORY_KEY)
    }
  }, [sessionContext.user])

  useEffect(() => {
    let id2 = EventEmitterService.subscribe(EventKey.USER_LOGGED_IN, () => {
      if (SessionService.isAdmin()) {
        loadFormOptions()
      } else if (
        sessionContext.user.userInsuredPersons.length > 0 &&
        !sessionContext.doOrphanVehiclesExist() &&
        sessionContext.user.userVehicles.every(x => x.new_vehicle_req_details == '' && x.model && x.make)
      ) {
        Promise.all([loadPlans(), loadFormOptions()])
      }
    })

    let id3 = EventEmitterService.subscribe(EventKey.FLOW_PROFILE_FINISH, () => {
      // In case State has changed so offers will reflect taxes
    })

    let id4 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
      dispatch({ type: 'RESET' })
    })

    let id5 = EventEmitterService.subscribe(EventKey.RECEIVED_ACTIVE_INSURANCE, activeInsurance => {
      dispatch({ type: 'ACTIVE_INSURANCE_CHANGED', data: activeInsurance })
    })

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.USER_LOGGED_IN, id2)
      EventEmitterService.unsubscribe(EventKey.FLOW_PROFILE_FINISH, id3)
      EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id4)
      EventEmitterService.unsubscribe(EventKey.RECEIVED_ACTIVE_INSURANCE, id5)
    }
  }, [state])

  useEffect(() => {
    if (
      state != defaultState &&
      state.plans.status === 'idle' &&
      !isInPolicyManagmentFlow(location.pathname) &&
      !isInAccountUpdateFlow(location.pathname)
    ) {
      awaitWithLoading(requestOffer())
    }
  }, [state.subscriptionStartTime, state.specialPrice, state.offerCart, state.billingPeriod, state.plans.status])

  useEffect(() => {
    //generatePlaidToken();

    // Reset Starting date after successfull purchase
    let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, data => {
      dispatch({ type: 'POST_PURCHASE', data })
    })

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id)
    }
  }, [])

  const setBillingPeriod = period => {
    dispatch({ type: 'SET_BILLING_PERIOD', data: period })
  }

  const changeStartingDate = startingDate => {
    dispatch({ type: 'CHANGE_STARTING_DATE', startingDate: startingDate })
  }

  const loadQuote = quote => {
    dispatch({ type: 'LOAD_QUOTE', data: quote })
  }

  const passSummary = shouldPass => {
    dispatch({ type: 'PASS_SUMMARY', data: shouldPass })
  }

  const passIssuance = shouldPass => {
    dispatch({ type: 'PASS_ISSUANCE', data: shouldPass })
  }

  const loadPlansAfterMvrChange = shouldLoadPlans => {
    const result = state.plans.shouldLoadPlans || shouldLoadPlans
    dispatch({ type: 'LOAD_PLANS_AFTER_MVR', data: result })
  }
  const passCreditCard = shouldPass => {
    dispatch({ type: 'PASS_CREDIT_CARD', data: shouldPass })
  }

  const setPaymentRequest = paymentRequest => {
    dispatch({ type: 'SET_PAYMENT_REQUEST', data: paymentRequest })
  }

  const setPaymentMethod = paymentMethod => {
    dispatch({ type: 'SET_PAYMENT_METHOD', data: paymentMethod })
  }

  const setClientSecret = token => {
    dispatch({ type: 'SET_CLIENT_SECRET', data: token })
  }

  const setIsBankLinked = isLinked => {
    dispatch({ type: 'LINKED_BANK_ACCOUNT', data: isLinked })
  }

  const flipSpecialPrice = () => {
    if (state.specialPrice.enabled == 'yes')
      dispatch({
        type: OFFERS_ACTIOS.SET_SPECIAL_PRICE,
        data: {
          enabled: 'no',
          custom_premium: state.specialPrice.custom_premium,
          custom_premium_token: state.specialPrice.custom_premium_token,
        },
      })
    else
      dispatch({
        type: OFFERS_ACTIOS.SET_SPECIAL_PRICE,
        data: {
          enabled: 'yes',
          custom_premium: state.specialPrice.custom_premium,
          custom_premium_token: state.specialPrice.custom_premium_token,
        },
      })
  }

  const setSpecialPrice = (premium, premiumToken) => {
    dispatch({
      type: OFFERS_ACTIOS.SET_SPECIAL_PRICE,
      data: { enabled: 'yes', custom_premium: premium, custom_premium_token: premiumToken },
    })
  }

  const getClientSecret = async () => {
    const { data } = await UserApi.getClientSecret()

    setClientSecret(data)
  }

  const choosePlan = plan => {
    let configuration = {
      vehicle_requests: {},
    }

    plan.coverages
      .filter(coverage => coverage.type == 'policy')
      .forEach(coverage => {
        configuration[coverage.name] = coverage.value
      })

    // let vehicleValues = {}
    // plan.coverages
    //   .filter(coverage => coverage.type == 'vehicle')
    //   .forEach(coverage => {
    //     vehicleValues[coverage.name] = coverage.value
    //   })

    sessionContext.user.userVehicles.forEach(vehicle => {
      configuration.vehicle_requests[vehicle.id] = {
        coverages: plan.vehicleCoverages[vehicle.id].reduce((vehicleValues, coverage) => {
          vehicleValues[coverage.name] = coverage.value
          return vehicleValues
        }, {}),
        is_diminishing_deductible: false,
        has_lienholder: vehicle.lienholder == 'yes',
      }
    })

    //TODO: remove this line
    coveragesApi.hackSavePlan(plan.id)

    enhancedCoversionEvent(sessionContext.user.email, sessionContext.user.phoneNumber, 'Continue-button-at-operators')
    dispatch({
      type: 'CART_CHANGED',
      data: {
        newOffer: configuration,
        isNewFlow: sessionContext.newPaymentFlow,
        administrativeDivision: sessionContext.user.administrativeDivision,
      },
    })
    dispatch({ type: 'SET_BILLING_PERIOD_DISCOUNTS', data: plan.periodicDiscounts })
    dispatch({ type: 'SET_IS_CUSTOMIZED_CART', data: false })
  }

  const updateOfferCart = (cart, isCustomizedCart) => {
    dispatch({
      type: 'CART_CHANGED',
      data: {
        newOffer: cart,
        isNewFlow: sessionContext.newPaymentFlow,
        administrativeDivision: sessionContext.user.administrativeDivision,
      },
    })
    dispatch({ type: 'SET_IS_CUSTOMIZED_CART', data: isCustomizedCart })
  }

  const purchaseOffer = async () => {
    return await coveragesApi.purchaseOffer(state.offerId)
  }

  const userSignatureRequiredForBrokerPurchase = () => {
    return SessionService.isBroker() && state.offerCardWarnings.violationsId.length > 0
  }

  const updateCoverages = async () => {
    const { vehicle_requests, ...policyCover } = state.offerCart
    const policyCoverages = {}
    for (const cov in policyCover) {
      policyCoverages[cov] = policyCover[cov]
    }
    const vehicleCoverages = {}
    for (const vehCov in vehicle_requests) {
      vehicleCoverages[vehCov] = {
        coverages: vehicle_requests[vehCov].coverages,
        is_diminishing_deductible: vehicle_requests[vehCov].is_diminishing_deductible,
      }
    }

    const request = {
      coverages: policyCoverages,
      vehicle_requests: vehicleCoverages,
    }
    try {
      const updateResponse = await UserApi.updateUserCoverages(request)
    } catch (error) {
      console.log(error)
    }
  }

  const updateQuoteData = async () => {
    const request = {
      billing_period: state.billingPeriod,
      start_date: state.subscriptionStartTime,
    }
    try {
      const updateResponse = await UserApi.updateUserQuoteData(request)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BuyingInsuranceContextStore.Provider
      value={{
        ...state,
        dispatch,
        choosePlan,
        setSpecialPrice,
        flipSpecialPrice,
        changeStartingDate,
        loadQuote,
        passSummary,
        passIssuance,
        passCreditCard,
        setPaymentRequest,
        setPaymentMethod,
        setClientSecret,
        getClientSecret,
        setIsBankLinked,
        setBillingPeriod,
        loadPlans,
        loadPlansAfterMvrChange,
        getOfferById,
        userSignatureRequiredForBrokerPurchase,
        updateOfferCart,
        checkCoveragesViolations,
        updateCoverages,
        updateQuoteData,
        resetOfferCart,
      }}
    >
      {props.children}
    </BuyingInsuranceContextStore.Provider>
  )
}

export default BuyingInsuranceContext
