import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import UserProfileModel from '../models/user-models/UserProfileModel'
import QuoteModel from '../models/insurance-models/QuoteModel'
import { buildQueryParamsUrl } from '../services/utils'

class BrokerApi {
  async findUserOnBehalfBroker(email, isInManagement) {
    return ApiService.fetch(`partners/users/user_token?isInManagement=${isInManagement}`, HTTPMethod.POST, { user_name: email }, true, true)
  }

  async getProfile(isInManagement) {
    let resArr = await Promise.all([
      ApiService.fetch(`users/me?isInManagement=${isInManagement}`, HTTPMethod.GET, undefined, true, true),
      this.getProfilePicture(),
    ])
    let res = resArr[0]
    let imageRes = resArr[1]
    if (res.ok) {
      let user: UserProfileModel = Transformer.initializeFromData(new UserProfileModel(), res.data)
      let split = UserProfileModel.fullNameSplitter(res.data.full_name)
      user.firstName = split[0]
      user.lastName = split[1]
      user.profilePicture = imageRes
      res.parsedData = user
    }
    return res
  }

  async getProfilePicture() {
    let res = await ApiService.fetch('users/me/profile_image', HTTPMethod.GET, undefined, true, true)
    if (res.ok) return res.data.image_url
    else return ''
  }

  async getQuotes() {
    let res = await ApiService.fetch('partners/recentSubmissions', HTTPMethod.GET, undefined, true, true)
    if (res.ok) {
      res.parsedData = res.data.map(q => Transformer.initializeFromData(new QuoteModel(), q))
    }
    return res
  }

  async getUsers() {
    let res = await ApiService.fetch('partners/affiliatedUsers', HTTPMethod.GET, undefined, true, true)
    if (res.ok) {
      res.parsedData = res.data.map(user => Transformer.parseUserModel(user))
    }
    return res
  }

  async generateProposalToken(email) {
    let res = await ApiService.fetch('partners/users/proposal_form_token', HTTPMethod.POST, { user_name: email }, true, true)
    return res
  }

  async generateSpecialPriceHash(offerId, customPremium, userName) {
    return await ApiService.fetch(
      'partners/users/custom_premium_token',
      HTTPMethod.POST,
      {
        user_name: userName,
        offer_id: offerId,
        custom_premium: customPremium,
      },
      true,
      true
    )
  }

  async searchUserByName(name) {
    let urlEncoded = buildQueryParamsUrl('users/me/searchUserByName', {
      name,
    })
    return ApiService.fetch(urlEncoded, HTTPMethod.GET)
  }

  async searchUserByPhoneNumber(phoneNumber) {
    let urlEncoded = buildQueryParamsUrl('users/me/searchUserByPhoneNumber', {
      phoneNumber,
    })
    return ApiService.fetch(urlEncoded, HTTPMethod.GET)
  }

  async searchUserByEmail(email) {
    let urlEncoded = buildQueryParamsUrl('users/me/searchUserByEmail', {
      email,
    })
    return ApiService.fetch(urlEncoded, HTTPMethod.GET)
  }
  async searchUserByPolicyNumber(policyNumber) {
    let urlEncoded = buildQueryParamsUrl('users/me/searchUserByPolicyNumber', {
      policyNumber,
    })
    return ApiService.fetch(urlEncoded, HTTPMethod.GET)
  }
}

export default new BrokerApi()
