import React, { useEffect, useState, useRef } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import { ControlledSignature } from '../Core/Input/ControlledSignature'
import { isMobile } from 'react-device-detect'
import FlexView from 'react-flexview/lib'
import { ContinueButton } from '../Core/Buttons/ContinueButton'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import ReactHtmlParser from 'react-html-parser'
import { awaitWithLoading } from '../../services/utils'
import policyModificationApi from '../../api/policyModification.api'
import { Container } from '@material-ui/core'
import { EventEmitterService, EventKey } from '../../services/EventEmitterService'
import { reportGTM } from '../Core/Utilities/GTagManger'
import coveragesApi from '../../api/coverages.api'

export const OnlineDigitalSigninForm = () => {
  const history = useHistory()
  const location = useLocation()
  const { ApplicationId } = queryString.parse(location.search)
  const form = useForm({ mode: 'onBlur' })
  const [htmls, sethtmls] = useState('')
  const ref = useRef(null)

  useEffect(() => {
    const getSigningForm = async () => {
      const res = await awaitWithLoading(coveragesApi.getOnlineRequiredSignatureFormsPDF(ApplicationId))
      sethtmls(res.data.htmls)
    }
    getSigningForm()
    reportGTM('', '', { event: 'select_reject_user_clicked' })
  }, [])

  const saveSigningForm = async () => {
    const signature = form.getValues()['signature']
    const res = await awaitWithLoading(coveragesApi.saveDigitalSignature(signature, ApplicationId))
    if (res.ok) {
      ref.current && ref.current.scrollIntoView({ behavior: 'smooth' })
      history.push('signing_congrats')
      reportGTM('', '', { event: 'signed_select_reject_in_portal' })
    } else {
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
  }

  return (
    <>
      <Container maxWidth={'md'}>
        <form onSubmit={form.handleSubmit(saveSigningForm)}>
          <div ref={ref} className="white-background">
            {Object.entries(htmls).map((x, key) => (
              <div id="embedded-html" key={key}>
                {ReactHtmlParser(x[1].replace('<img height="200" width="900"', '<img height="100%" width="100%"'))}
              </div>
            ))}
          </div>
          <FlexView vAlignContent="center" hAlignContent="center" className="margin-top-large sign_position" column={isMobile}>
            <ControlledSignature
              className={`margin-top-medium margin-bottom-medium`}
              control={form.control}
              errors={form.errors}
              getValues={form.getValues}
              setValue={form.setValue}
            />
            <ContinueButton
              label="Complete"
              type="submit"
              className={{ 'margin-bottom-medium margin-top-large': isMobile }}
              isGreenButton={false}
            />
          </FlexView>
        </form>
      </Container>
    </>
  )
}
