import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import UserProfileModel from '../models/user-models/UserProfileModel'
import State from '../models/user-models/State'
import dayjs from 'dayjs'
import Resizer from '../utilities/Resizer'
import { convertToYesOrNo, dataImageToFile, isInPolicyManagmentFlow, removeDuplicateAdditionalInsured } from '../services/utils'
import CreditCardModel from '../models/user-models/CreditCardModal'
import { capitalizeFirstLetter } from '../services/utils'
import DataLayerService from '../services/DataLayerService'
import StorageService, { StorageKey } from '../services/StorageService'

export class UserApi {
  static async getProfile(isInManagement) {
    let resArr = await Promise.all([ApiService.fetch(`users/me?isInManagement=${isInManagement}`), UserApi.getProfilePicture()])
    let res = resArr[0]
    let imageRes = resArr[1]
    if (res.ok) {
      let user: UserProfileModel = Transformer.initializeFromData(new UserProfileModel(), res.data)
      user.profilePicture = imageRes
      user.state = res.data.administrative_division
      user.userVehicles.map(vehicle =>
        Object.assign(vehicle, {
          anti_theft_device: convertToYesOrNo(vehicle.anti_theft_device),
          anti_lock_brakes: convertToYesOrNo(vehicle.anti_lock_brakes),
          less_than_year_acquired: vehicle.less_than_year_acquired.toString(),
          ownership_period: vehicle.ownership_period,
          is_motorcycle_include_odometer: convertToYesOrNo(vehicle.is_motorcycle_include_odometer),
        })
      )
      user.userInsuredPersons.map(operator =>
        Object.assign(operator, {
          license_endorsed_for_motorcycle: convertToYesOrNo(operator.license_endorsed_for_motorcycle),
          own_home: convertToYesOrNo(operator.own_home),
          residence_insurance: convertToYesOrNo(operator.residence_insurance),
          active_military: convertToYesOrNo(operator.active_military),
          recent_accident: convertToYesOrNo(operator.recent_accident),
          is_married: operator.is_married == 'True' ? 'true' : 'false',
          isNewMvrInfo: StorageService.getItem('IS_NEW_MVR_INFORMATION') === 'true',
        })
      )
      res.parsedData = user

      //TODO: a better solution to Set user id for Google Tag Manager Service
      DataLayerService.userId = user.userId
    }

    return res
  }

  static async getStates() {
    let response = await ApiService.fetch('general/administrative_divisions')
    if (response.ok) {
      let states = []
      for (let value in response.data.administrative_divisions) {
        let state = new State()
        state.code = value
        state.name = response.data.administrative_divisions[value]
        states.push(state)
      }
      response.parsedData = states
    }
    return response
  }

  static async getIsActiveStates(isActive) {
    let response = await ApiService.fetch(`general/administrative_divisions?isActive=${isActive}`)
    if (response.ok) {
      let states = []
      for (let value in response.data.administrative_divisions) {
        let state = new State()
        state.code = value
        state.name = response.data.administrative_divisions[value]
        states.push(state)
      }
      response.parsedData = states
    }
    return response
  }

  static async getPaymentFlow() {
    let response = await ApiService.fetch('general/payment_flow')
    if (response.ok) {
      response.parsedData = response.data.new_flow
    }
    return response
  }

  static async isPeriodicPaymentEnabled() {
    let response = await ApiService.fetch('general/periodic_payment_enabled')
    if (response.ok) {
      response.parsedData = response.data.is_enabled
    }
    return response
  }

  static async saveProfilePicture(profileImage) {
    let newProfileImage = await Resizer.imageFileResizer(profileImage, 250, 250, 'JPEG', 100, 0, 'base64')
    let resizedImage = dataImageToFile(newProfileImage, profileImage.name)
    return ApiService.uploadFile('users/me/profile_image/upload', resizedImage)
  }

  static async saveProfile(profile, isManagement) {
    let res = await ApiService.fetch('users/me', HTTPMethod.POST, {
      first_name: capitalizeFirstLetter(profile.user_info.fname.toLowerCase()),
      last_name: capitalizeFirstLetter(profile.user_info.lname.toLowerCase()),
      company: profile.user_info.company_name,
      address1: profile.address.address_line_1,
      address2: profile.address.address_line_2,
      city: profile.address.city,
      administrative_division: profile.address.state,
      zip_code: profile.address.zipcode,
      commercial: profile.insurance_history.is_commercial == 'yes',
      date_of_birth: dayjs(profile.user_info.dob).format('YYYY-MM-DD'),
      social_security_number: profile.user_info.social_security_number,
      insurance_in_past_year: profile.insurance_history.insurance_in_past_year == 'yes',
      current_insurer: profile.insurance_history.current_insurer,
      phone_number: profile.user_info.phone_number,
    })
    if (res.ok) {
      return UserApi.getProfile(isManagement)
    } else {
      return res
    }
  }

  static async getProfilePicture() {
    let res = await ApiService.fetch('users/me/profile_image')
    if (res.ok) return res.data.image_url
    else return ''
  }

  static async attachPaymentMethod(paymentMethodToken, isAttachedAlready, method) {
    return await ApiService.fetch(
      'creditcards/me/attach-payment-method',
      HTTPMethod.POST,
      {
        payment_method_token: paymentMethodToken,
        is_attached_already: isAttachedAlready,
        method,
      },
      true
    )
  }

  static async updateCreditCard(token) {
    return await ApiService.fetch('creditcards/me', HTTPMethod.POST, token.id)
  }

  static async getCreditCardInfo() {
    let res = await ApiService.fetch('creditcards/me')
    if (res.ok) {
      let creditCardModel = new CreditCardModel()
      creditCardModel.lastFourDigits = res.data.last_four_digits
      creditCardModel.expMonth = res.data.expiration_month.toString()
      creditCardModel.expYear = res.data.expiration_year.toString()
      creditCardModel.brand = res.data.brand ? res.data.brand.toLowerCase() : 'visa'
      res.parsedData = creditCardModel
    }
    return res
  }

  static async getClientSecret() {
    let res = await ApiService.fetch('creditcards/me/client-secret')
    if (!res.ok) return

    return res
  }

  static async addBankAccount(public_token, account_id) {
    let res = await ApiService.fetch('creditcards/me/add-bank-account', HTTPMethod.POST, {
      public_token: public_token,
      account_id: account_id,
    })
    if (!res.ok) return
    res.parsedData = { bankToken: res.data }

    return res
  }

  static async patchUserState(state) {
    return await ApiService.fetch('users/me', HTTPMethod.PATCH, [
      {
        value: state,
        path: '/administrative_division',
        op: 'replace',
      },
    ])
  }

  static async setIsCommercial() {
    return await ApiService.fetch('users/me', HTTPMethod.PATCH, [
      {
        value: true,
        path: '/commercial',
        op: 'replace',
      },
    ])
  }

  static async getAdditionalInsureds() {
    let res = await ApiService.fetch('users/me/additionalInsured')
    if (res.ok && res.data) {
      res.parsedData = removeDuplicateAdditionalInsured(res.data.sort((a, b) => b.id - a.id))
    }
    return res
  }

  static async updateUserPhoneNumber(phone_number) {
    return ApiService.fetch(`users/me/update_user_phone_number`, HTTPMethod.POST, {
      phone_number: phone_number,
    })
  }

  static async updateUserCoverages(request) {
    let response = await ApiService.fetch('users/me/update_user_coverages', HTTPMethod.POST, request)
    if (response.ok) {
      response.parsedData = response.data
    }
    return response
  }

  static async updateUserQuoteData(request) {
    let response = await ApiService.fetch('users/me/update_user_quote_data', HTTPMethod.POST, request)
    if (response.ok) {
      response.parsedData = response.data
    }
    return response
  }

  static async useRedemptionCode(redemptionCode) {
    if (!redemptionCode) return

    return ApiService.fetch('users/me/redeem_code', HTTPMethod.POST, {
      redeem_code: redemptionCode,
    })
  }
}
