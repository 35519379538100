import React, { useReducer, useEffect, useContext } from 'react'
import UserProfileModel from '../models/user-models/UserProfileModel'
import { AuthApi } from '../api/auth.api'
import AnalyticsApi from '../api/analytics.api'
import { SessionService } from '../services/SessionService'
import { UserApi } from '../api/user.api'
import { EventEmitterService, EventKey } from '../services/EventEmitterService'
import StorageService, { StorageKey } from '../services/StorageService'
import brokerApi from '../api/broker.api'
import { awaitWithLoading, isInPolicyManagmentFlow } from '../services/utils'
import { useHistory, useLocation } from 'react-router-dom'
import BrokerConfigService from '../services/BrokerConfigService'
import { ONGOING_PURCHASE } from './InsuracePurchaseContext'
import { OFFERS_HISTORY_KEY } from './BuyingInsuranceContext'
import DataLayerService from '../services/DataLayerService'
import { ActiveInsuranceModel } from '../models/insurance-models/ActiveInsuranceModel'
import insuranceApi from '../api/insurance.api'
import { reportGTM } from '../components/Core/Utilities/GTagManger'
import { ACTIONS } from './UserProfileContext'
import { getCookie } from '../utilities/cookies'

export const SESSION_ACTIONS = {
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_USER_PAYMENT_FLOW: 'UPDATE_USER_PAYMENT_FLOW',
  UPDATE_VEHICLE: 'UPDATE_VEHICLE',
  UPDATE_OPERATOR: 'UPDATE_OPERATOR',
  SET_USER_COMMERCIAL: 'SET_USER_COMMERCIAL',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  SET_BROKER: 'SET_BROKER',
  LOGOUT: 'LOGOUT',
  ACTIVE_INSURANCE_UPDATE: 'ACTIVE_INSURANCE_UPDATE',
  CC_SAVED: 'CC_SAVED',
  UPDATE_STATE: 'Update_State',
  ADD_VEHICLE: 'ADD_VEHICLE',
  ADD_OPERATOR: 'ADD_OPERATOR',
  DELETE_OPERATOR: 'DELETE_OPERATOR',
  SET_CREDIT_CARD_ALERT: 'SET_CREDIT_CARD_ALERT',
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
  REST_REDIRECT_URL: 'REST_REDIRECT_URL',
}

const defaultState = {
  user: new UserProfileModel(),
  creditCardAlert: false,
  selctedState: '',
  isActiveState: false,
  broker: new UserProfileModel(),
  activeInsurance: new ActiveInsuranceModel(),
  redirectUrl: null, //we use this in case the user had expired token and we want to save the redirect url for the after login.
  login: (userName, password) => {
    throw 'Not Implemented'
  },
  passwordlessLogin: (userName, lastname, dob, zip) => {
    throw 'Not Implemented'
  },
  logout: () => {
    throw 'Not Implemented'
  },
  socialLogin: () => {
    throw 'Not Implemented'
  },
  register: () => {
    throw 'Not Implemented'
  },
  registerPasswordless: () => {
    throw 'Not Implemented'
  },
  deeplinkTokenEntrance: (profile, token) => {
    throw 'Not Implemented'
  },
  bindUserForBroker: (email, action) => {
    throw 'Not implemented'
  },
  loadUser: () => {
    throw 'Not implemented'
  },
  updateState: (state, isActiveState) => {
    throw 'Not implemented'
  },
  isBookRoll: source => {
    throw 'Not implemented'
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case SESSION_ACTIONS.UPDATE_STATE:
      return {
        ...state,
        selectedState: action.data.state,
        isActiveState: action.data.isActiveState,
      }
    case SESSION_ACTIONS.ADD_VEHICLE:
      return {
        ...state,
        user: {
          ...state.user,
          userVehicles: [...state.user.userVehicles, action.data],
        },
      }
    case SESSION_ACTIONS.UPDATE_VEHICLE: {
      const vehicleIndex = state.user.userVehicles.findIndex(q => q.id == action.data.id)
      if (vehicleIndex > -1) {
        const newState = { ...state }
        newState.user.userVehicles[vehicleIndex] = action.data
        return newState
      }
      return state
    }

    case SESSION_ACTIONS.UPDATE_OPERATOR: {
      const operatorIndex = state.user.userInsuredPersons.findIndex(q => q.id == action.data.id)
      if (operatorIndex > -1) {
        const newState = { ...state }
        newState.user.userInsuredPersons[operatorIndex] = action.data
        return newState
      }
      return state
    }

    case SESSION_ACTIONS.ADD_OPERATOR:
      return {
        ...state,
        user: {
          ...state.user,
          userInsuredPersons: [...state.user.userInsuredPersons, action.data],
        },
      }
    case SESSION_ACTIONS.DELETE_OPERATOR:
      return {
        ...state,
        user: {
          ...state.user,
          userInsuredPersons: state.user.userInsuredPersons.filter(op => op.id != action.data),
        },
      }
    case SESSION_ACTIONS.SET_USER_COMMERCIAL:
      let user = Object.assign(state.user, { commercial: true })
      return {
        ...state,
        user: user,
      }
    case SESSION_ACTIONS.CC_SAVED:
      let user_cc = Object.assign(state.user, { hasCreditCard: true })
      return {
        ...state,
        user: user_cc,
      }
    case SESSION_ACTIONS.ACTIVE_INSURANCE_UPDATE:
      return { ...state, activeInsurance: action.data }
    case SESSION_ACTIONS.SET_BROKER:
      return { ...state, broker: action.data }
    case SESSION_ACTIONS.UPDATE_USER_PROFILE:
      return {
        ...state,
        user: action.data,
      }
    case SESSION_ACTIONS.UPDATE_USER_PAYMENT_FLOW:
      return {
        ...state,
        newPaymentFlow: action.data,
      }
    case SESSION_ACTIONS.UPDATE_PROFILE_PICTURE:
      return { ...state, user: Object.assign({}, state.user, { profilePicture: action.url }) }
    case SESSION_ACTIONS.LOGOUT:
      return {
        ...state,
        user: action.data,
        broker: action.data,
        activeInsurance: new ActiveInsuranceModel(),
      }
    case SESSION_ACTIONS.SET_CREDIT_CARD_ALERT:
      reportGTM('', '', { event: 'cc-exp-notice' })
      return {
        ...state,
        creditCardAlert: action.data,
      }
    case SESSION_ACTIONS.SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.data }
    case SESSION_ACTIONS.REST_REDIRECT_URL:
      return { ...state, redirectUrl: null }
    default:
      return state
  }
}

export const SessionContextStore = React.createContext(defaultState)
SessionContextStore.displayName = 'Session Context'

const SessionContext = props => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const history = useHistory()
  const location = useLocation()

  const loadActiveInsurance = async () => {
    let activeInsurance = await insuranceApi.getActiveInsurances()
    if (activeInsurance.ok) {
      dispatch({ type: SESSION_ACTIONS.ACTIVE_INSURANCE_UPDATE, data: activeInsurance.parsedData })
    }
  }

  const updateState = (state, isActiveState) => {
    dispatch({
      type: SESSION_ACTIONS.UPDATE_STATE,
      data: {
        state,
        isActiveState,
      },
    })
  }

  useEffect(() => {
    let id3 = EventEmitterService.subscribe(EventKey.USER_UNAUTHORIZED, () => {
      // TODO: should use entrance for the odometer!
      if (location.pathname.startsWith('/odometer') || location.pathname.startsWith('/entrance')) return

      logout()
      history.replace('/login')
    })
    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.USER_UNAUTHORIZED, id3)
    }
  }, [history, location])

  useEffect(() => {
    if (SessionService.isLoggedIn()) {
      if (SessionService.isBroker()) {
        brokerApi.getProfile(isInPolicyManagmentFlow(location.pathname)).then(userRes => {
          dispatch({ type: SESSION_ACTIONS.SET_BROKER, data: userRes.parsedData })
          props.setInitialized(true)
        })
      }
      UserApi.getProfile(isInPolicyManagmentFlow(location.pathname))
        .then(userRes => {
          if (
            userRes.ok
            // || userRes.status === 401 || userRes.status === 403
          ) {
            dispatch({ type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: userRes.parsedData })
          }
        })
        .finally(() => props.setInitialized(true))
      if (state.newPaymentFlow == null) {
        UserApi.getPaymentFlow().then(res => dispatch({ type: SESSION_ACTIONS.UPDATE_USER_PAYMENT_FLOW, data: res.parsedData }))
      }
      loadActiveInsurance()
    } else {
      props.setInitialized(true)
    }

    const id = EventEmitterService.subscribe(EventKey.USER_ACCEPT_COMMERCIAL, () => {
      if (SessionService.isLoggedIn()) {
        UserApi.setIsCommercial()
      } else {
        StorageService.setItem(StorageKey.USER_IS_COMMERCIAL, true)
      }
      dispatch({ type: SESSION_ACTIONS.SET_USER_COMMERCIAL })
    })

    const id2 = EventEmitterService.subscribe(EventKey.USER_PROFILE_IMAGE_UPDATED, data => {
      //TODO: dispatching this event to change the profile picture will make the user details forms to "reset" their state's
      // dispatch({type: SESSION_ACTIONS.UPDATE_PROFILE_PICTURE, url: data.url})
    })

    const id3 = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, data => {
      dispatch({ type: SESSION_ACTIONS.ACTIVE_INSURANCE_UPDATE, data: data })
    })

    const id4 = EventEmitterService.subscribe(EventKey.FLOW_CC_FINISH, () => {
      dispatch({ type: SESSION_ACTIONS.CC_SAVED })
    })

    const id5 = EventEmitterService.subscribe(EventKey.REDEEM_CODE_APPLIED, () => {
      loadUser()
    })

    const id6 = EventEmitterService.subscribe(EventKey.UPDATE_OPERATOR, data => {
      dispatch({ type: SESSION_ACTIONS.UPDATE_OPERATOR, data: data })
    })

    const id7 = EventEmitterService.subscribe(EventKey.UPDATE_VEHICLE, data => {
      dispatch({ type: SESSION_ACTIONS.UPDATE_VEHICLE, data: data })
    })

    const id8 = EventEmitterService.subscribe(EventKey.UPDATE_USER_PROFILE, data => {
      dispatch({ type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: data })
    })

    return function cleanup() {
      EventEmitterService.unsubscribe(EventKey.USER_ACCEPT_COMMERCIAL, id)
      EventEmitterService.unsubscribe(EventKey.USER_PROFILE_IMAGE_UPDATED, id2)
      EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id3)
      EventEmitterService.unsubscribe(EventKey.FLOW_CC_FINISH, id4)
      EventEmitterService.unsubscribe(EventKey.REDEEM_CODE_APPLIED, id5)
      EventEmitterService.unsubscribe(EventKey.UPDATE_OPERATOR, id6)
      EventEmitterService.unsubscribe(EventKey.UPDATE_VEHICLE, id7)
      EventEmitterService.unsubscribe(EventKey.UPDATE_USER_PROFILE, id8)
    }
  }, [])

  // useEffect(() => {
  //   state.user.userId > 0 && props.setInitialized()
  // }, [state.user.userId])

  const login = async (userName, password) => {
    let res = await AuthApi.login(userName, password)
    await afterLogin(userName, res, 'skywatch-email-password')
    return res
  }

  const passwordlessLogin = async (userName, lastname, dob, zip) => {
    let res = await AuthApi.passwordlessLogin(userName, lastname, dob, zip)
    await afterLogin(userName, res, 'skywatch-email-lastname-dob-zip')
    return res
  }

  const deeplinkTokenEntrance = async (profile, token) => {
    let res = await AuthApi.deeplinkTokenEntrance(profile, token)
    let currentToken = SessionService.getToken()
    let currentEmail = SessionService.getEmailToken()
    const url = (res.ok && res.data.token) || (currentToken && currentEmail) ? res.data.redirect_url : '/login'
    if (!(res.ok && res.data.token) && !currentToken && res.data.redirect_url) {
      setRedirectUrl(`${res.data.redirect_url}`)
    }
    if (res.ok) {
      StorageService.removeItem(OFFERS_HISTORY_KEY)
    }
    const email = res.ok ? (res.data.email ? res.data.email : currentEmail) : ''
    res.parsedData = res.data
    res.data.token = res.data.token ? res.data.token : currentToken

    await afterLogin(email, res, `deeplinkLogin-${profile}`)
    return url
  }

  const socialLogin = async (email, serviceName, token) => {
    let res = await AuthApi.socialLogin(email, serviceName, token)

    if (res.ok) {
      if (!BrokerConfigService.isSkywatchBroker()) {
        BrokerConfigService.getRedeemCode().then(redeemCode => {
          UserApi.u(redeemCode)
        })
      }
    }

    afterLogin(email, res, serviceName)
    return res
  }

  const afterLogin = async (userName, res, serviceName) => {
    if (!res.ok || !userName) return
    reportGTM('', '', {
      event: 'successful-login',
      type: serviceName,
    })
    SessionService.setUserToken(userName, res.parsedData.token)
    // send marketing analytics
    AnalyticsApi.addUtmLog('register', getCookie('page_full_url'))
    UserApi.getPaymentFlow().then(res => dispatch({ type: SESSION_ACTIONS.UPDATE_USER_PAYMENT_FLOW, data: res.parsedData }))

    loadActiveInsurance()
    return loadUser()
  }

  const loadUser = async () => {
    let userRes = await UserApi.getProfile(isInPolicyManagmentFlow(location.pathname))
    if (userRes.ok) {
      let user = userRes.parsedData

      if (StorageService.getItem(StorageKey.USER_IS_COMMERCIAL)) {
        await UserApi.setIsCommercial()
        user = Object.assign(user, { commercial: true })
        StorageService.removeItem(StorageKey.USER_IS_COMMERCIAL)
      }
      if (SessionService.isBroker()) {
        dispatch({ type: SESSION_ACTIONS.SET_BROKER, data: user })
      }
      dispatch({ type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: user })
    }
    return userRes
  }

  const isBookRoll = () => {
    return state.user.source === 'BookRoll'
  }

  const register = async (email, password) => {
    let res = await AuthApi.register(email, password)
    if (res.ok) {
      reportGTM('', '', {
        event: 'successful-registration',
        type: 'skywatch-email-password',
      })
      // send marketing analytics
      AnalyticsApi.addUtmLog('register', getCookie('page_full_url'))
      //

      let loginRes = await login(email, password)
      if (!BrokerConfigService.isSkywatchBroker()) {
        let redeemCode = await BrokerConfigService.getRedeemCode()
        await UserApi.useRedemptionCode(redeemCode)
      }
      return loginRes
    }
    return res
  }

  const registerPasswordless = async (email, zipCode, state) => {
    let res = await AuthApi.registerPasswordless(email, zipCode, state)
    if (res.ok) {
      reportGTM('', '', {
        event: 'successful-passwordless-registration',
        type: 'skywatch-email-passwordless',
      })
      //
      let loginRes = await afterLogin(email, res, 'skywatch-email-passwordless')

      if (!BrokerConfigService.isSkywatchBroker()) {
        let redeemCode = await BrokerConfigService.getRedeemCode()
        await UserApi.useRedemptionCode(redeemCode)
      }

      return loginRes
    }
    return res
  }

  const markelLogin = async markelLoginRequest => {
    let res = await AuthApi.markelLogin(markelLoginRequest)
    await handleMrkelPostLogin(res)
    return res
  }

  const markelLoginMissingData = async markelLoginMissingDataRequest => {
    let res = await AuthApi.markelLoginMissingData(markelLoginMissingDataRequest)
    await handleMrkelPostLogin(res)
    return res
  }

  const handleMrkelPostLogin = async response => {
    if (response.ok && response.parsedData.token && response.parsedData.user_name) {
      await afterLogin(response.parsedData.user_name, response, 'markel-login')
    }
  }

  const markelPhoneLogin = async request => {
    let res = await AuthApi.markelPhoneLogin(request)
    return res
  }

  const markelVerifyCode = async request => {
    let res = await AuthApi.markelVerifyCode(request)
    await handleMrkelPostLogin(res)
    return res
  }

  const markelPhoneLoginMissingData = async request => {
    let res = await AuthApi.markelPhoneLoginMissingData(request)
    await handleMrkelPostLogin(res)
    return res
  }

  const logout = () => {
    AuthApi.logout()
    SessionService.deleteSession()
    dispatch({ type: SESSION_ACTIONS.LOGOUT, data: new UserProfileModel() })
    EventEmitterService.dispatch(EventKey.SESSION_USER_LOGOUT)
    StorageService.removeItem(StorageKey.USER_IS_COMMERCIAL)
    //TODO: logging out from broker page makes the InsurancePurchaseContext not valid so the callback to delete state is never called
    StorageService.removeItem(ONGOING_PURCHASE)
    StorageService.removeItem(OFFERS_HISTORY_KEY)
    DataLayerService.userId = -1
  }

  const bindUserForBroker = async (email, action) => {
    EventEmitterService.dispatch(EventKey.ShowLoader)
    let res = await brokerApi.findUserOnBehalfBroker(email, action == 'annualBind' ? false : true)
    if (res.ok) {
      StorageService.removeItem(OFFERS_HISTORY_KEY)
      SessionService.setBrokerOnBehalfToken(res.data.token)
      let userRes = await UserApi.getProfile(action == 'annualBind' ? false : true)
      EventEmitterService.dispatch(EventKey.DismissLoader)
      if (userRes.ok) {
        EventEmitterService.dispatch(EventKey.USER_LOGGED_IN)
        dispatch({ type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: userRes.parsedData })
        await loadActiveInsurance()
      }
    } else {
      EventEmitterService.dispatch(EventKey.DismissLoader)
      EventEmitterService.dispatch(EventKey.ShowError, res)
    }
    return res
  }

  const doOrphanVehiclesExist = () => {
    let allCoveredVehicleIds = state.user.userInsuredPersons.flatMap(q => q.user_vehicle_details).map(q => q.id)
    let allAddedVehicleIds = state.user.userVehicles.map(q => q.id)
    return !allAddedVehicleIds.every(vehicleId => allCoveredVehicleIds.includes(vehicleId))
  }

  const setRedirectUrl = url => {
    dispatch({ type: SESSION_ACTIONS.SET_REDIRECT_URL, data: url })
  }

  const restRedirectUrl = () => {
    dispatch({ type: SESSION_ACTIONS.REST_REDIRECT_URL, data: null })
  }

  return (
    <SessionContextStore.Provider
      value={{
        ...state,
        dispatch,
        login,
        passwordlessLogin,
        deeplinkTokenEntrance,
        logout,
        loadUser,
        socialLogin,
        register,
        registerPasswordless,
        bindUserForBroker,
        doOrphanVehiclesExist,
        loadActiveInsurance,
        updateState,
        isBookRoll,
        markelLogin,
        markelLoginMissingData,
        markelPhoneLogin,
        markelVerifyCode,
        markelPhoneLoginMissingData,
        setRedirectUrl,
        restRedirectUrl,
      }}
    >
      {props.children}
    </SessionContextStore.Provider>
  )
}

export default SessionContext
