import { EventEmitterService, EventKey } from './EventEmitterService'
import { FirebaseService, FirebaseConfigKey } from './FirebaseService'
import { BillingPeriod, InsuranceTypes } from '../models/insurance-models/InsuranceBillingPeriodModel'
import dayjs from 'dayjs'
import BrokerConfigService from './BrokerConfigService'
import {
  COUNTRIES,
  WEIRD_GENERATED_GUID,
  estimatedMileageOption,
  estimatedMileageSteperMarks,
  estimatedNotAllowedForMarkelAffiliate,
} from '../Constants'
import { COVERAGE_TYPES } from '../models/insurance-models/CoverageType'
import React from 'react'
import { reportGTM } from '../components/Core/Utilities/GTagManger'

export const parseJwt = token => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

export function getInsuredTitle() {
  return ' '
}

export function convertToYesOrNo(val) {
  if (val) return 'yes'
  else return 'no'
}

export function changeFavicon() {
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'icon'
  link.href = BrokerConfigService.getFavicon()
  document.getElementsByTagName('head')[0].appendChild(link)
}

export function isTodayBreakdown(day, month, year) {
  //getMonth on a january date returns 0
  var iMonth = month === 0 ? 1 : month
  if (!day || !iMonth || !year) return false
  const today = new Date()
  return day == today.getDate() && month == today.getMonth() && year == today.getFullYear()
}

export function isToday(someDate) {
  if (!someDate) return false
  return isTodayBreakdown(someDate.getDate(), someDate.getMonth(), someDate.getFullYear())
}

export function prange(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start
    start = 0
  }

  if (typeof step == 'undefined') {
    step = 1
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return []
  }

  var result = []
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i)
  }
  return result
}

export function isOver18(birthday) {
  // birthday is a date
  if (!birthday) return false
  let now = dayjs()
  let diff = now.diff(birthday, 'year')
  return diff >= 18
}

export function isOver16(birthday) {
  // birthday is a date
  if (!birthday) return false
  let now = dayjs()
  let diff = now.diff(birthday, 'year')
  return diff >= 16
}

export const buildQueryParamsUrl = (url, parameters) => {
  let qs = ''
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key]
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&'
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1) //chop off last "&"
    url = url + '?' + qs
  }

  return url
}

export const getSupportMail = () => {
  return `mailto:support@skywatch.ai?subject=Support Request [${FirebaseService.getValue(FirebaseConfigKey.COUNTRY)}] - Web`
}

export const getErrorFromServerResponse = res => {
  let error = { title: 'Unknown Error', message: '' }
  if (!res) return error
  else if (res.ok) {
    return null
  } else if (res.data && res.data.title) {
    let messages = []
    if (!!res.data.messages.card_error) {
      res.data.messages.card_error = [
        <div style={{ textAlign: 'left', margin: 'auto' }}>
          <div style={{ fontWeight: 'bold' }}>Unfortunately we couldn't process your payment</div> <br />
          <div>Some of the most common reasons for this are:</div>
          <ul>
            <li>You may have entered your credit card details incorrectly</li>
            <li>You may have insufficient funds on your credit card</li>
          </ul>
          <br />
          <div style={{ textAlign: 'center' }}>Please try again</div>
        </div>,
      ]
    }
    for (let message in res.data.messages) {
      for (let mess of res.data.messages[message]) {
        messages.push(mess)
      }
    }
    if (!messages || messages.length < 1) messages = ['']
    if (res.status == 400 && (!res.data || !res.data.messages || Object.keys(res.data.messages).length > 0)) {
      if (!!res.data.messages.card_error) {
        error.title = 'Payment Error'
      } else {
        error.title = res.data.title || 'Please Note'
      }
    } else {
      error.title = res.data.title
    }
    error.message = messages[0]
    if (messages[1]) {
      messages[1] == 'declination' && reportGTM('', '', { event: 'declined_policy_popup' })
    }
  } else {
    error.message = `Error code: ${res.status}`
  }
  return error
}

export const awaitWithLoading = async promise => {
  EventEmitterService.dispatch(EventKey.ShowLoader)
  let res = await promise
  EventEmitterService.dispatch(EventKey.DismissLoader)
  return res
}

export const awaitWithLoadingStripe = async promise => {
  EventEmitterService.dispatch(EventKey.ShowPaymentLoader)
  let res = await promise
  EventEmitterService.dispatch(EventKey.DismissPaymentLoader)
  return res
}

export const showError = (title, message) => {
  let res = { data: { title: title, messages: { error: [message] } } }
  EventEmitterService.dispatch(EventKey.ShowError, res)
}

export const dataImageToFile = (file, filename) => {
  var arr = file.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const currencySymbol = () => {
  return FirebaseService.getValue(FirebaseConfigKey.CURRENCY_SYMBOL)
}

export const liabilityLimitPretty = limit => {
  return FirebaseService.getValue(FirebaseConfigKey.CURRENCY_SYMBOL) + limit / 1000000 + 'M'
}

export const CentPretty = (num, centSize = 15) => {
  if (num >= 1)
    return (
      <>
        <span>
          {num.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </>
    )
  else
    return (
      <>
        <span>{Math.ceil(num * 100)}</span>
        <span style={{ fontSize: `${centSize}px` }}>¢</span>
      </>
    )
}

export const KPretty = limit => {
  const parts = limit.split(' ')
  let num = Number.parseInt(parts[0])
  if (num < 1000) return FirebaseService.getValue(FirebaseConfigKey.CURRENCY_SYMBOL) + parts[0]
  let res = FirebaseService.getValue(FirebaseConfigKey.CURRENCY_SYMBOL) + parts[0] / 1000 + 'K'
  return parts.length > 1 ? res + ` ${parts[1]}` : res
}

export const isInFlow = (path: String) => {
  return path.startsWith('/insurance') || path.startsWith('/proposal') || path.startsWith('/claim')
}

export const isInPurchaseFlow = (path: String) => {
  return path.startsWith('/insurance')
}
export const isInIssuancePage = (path: String) => {
  return path.startsWith('/insurance/issuance')
}
export const isInClaimsFlow = (path: String) => {
  return path.startsWith('/claim')
}

export const isInPolicyManagmentFlow = (path: String) => {
  return path.startsWith('/policy-management')
}

export const isInAccountUpdateFlow = (path: String) => {
  return path.startsWith('/account')
}
export const billingPeriodPretty = biliingPeriod => {
  if (BillingPeriod.MONTH == biliingPeriod) return biliingPeriod + 'ly'
  else if (InsuranceTypes.MONTHLY == biliingPeriod) return 'Monthly'
  else if (InsuranceTypes.THREEMONTHS == biliingPeriod) {
    return '3 Months'
  }
  // else if(BillingPeriod.YEAR == biliingPeriod || InsuranceTypes.YEARLY == biliingPeriod)
  //   return 'Annual'
  else return biliingPeriod
}

export const coverageTypePretty = coverageType => {
  if (coverageType == COVERAGE_TYPES.LIABILITY) return 'Third Party Liability'
  return 'Combined Coverage'
}

export const getTotalHullValue = (drones, equipment) => {
  return drones.reduce((res, drone) => res + drone.value, 0) + equipment.reduce((res, eq) => res + eq.value, 0)
}

export const isUK = () => FirebaseService.getValue(FirebaseConfigKey.COUNTRY) == COUNTRIES.UK

export const isCanada = () => FirebaseService.getValue(FirebaseConfigKey.COUNTRY) == COUNTRIES.CANADA

export const generateProposalLink = token => {
  return `https://${window.location.hostname}/proposal/insurance/profile?token=${token}`
}

export const addDays = (dt, days) => {
  var date = dayjs(dt)
  return date.add(days, 'day')
}

export const equivalentAdditionalInsured = (additionalInsured1, additionalInsured2) => {
  return (
    additionalInsured1.name == additionalInsured2.name &&
    additionalInsured1.address == additionalInsured2.address &&
    additionalInsured1.city == additionalInsured2.city &&
    additionalInsured1.state == additionalInsured2.state &&
    additionalInsured1.zip == additionalInsured2.zip
  )
}

export const removeDuplicateAdditionalInsured = additionalInsuredList => {
  return additionalInsuredList.filter((value, index, self) => self.find(q => equivalentAdditionalInsured(q, value)) == value)
}

export const getAiWithoutId = ai => {
  return { ...ai, id: undefined }
}

export const fixWrongGeneratedGuid = (hullList: []) => {
  return hullList.map(item => (item.id == WEIRD_GENERATED_GUID ? Object.assign({}, item, { id: '' }) : item))
}

export const textToConst = text => {
  return text.replace('/', '').toLowerCase().replaceAll('  ', '_').replaceAll(' ', '_')
}

export const capitalizeFirstLetter = text => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const capitalizeAllLetters = text => {
  return text.toUpperCase()
}

export const vinValidation = e => {
  const validCharactersRegex = /^[0-9a-zA-Z\b]+$/
  if (validCharactersRegex.test(e.target.value)) {
    if (e.target.value.length > 17) {
      e.target.value = e.target.value.slice(0, 17)
    } else {
      e.target.value = e.target.value
    }
  } else {
    e.target.value = e.target.value.replaceAll(/[^a-zA-Z0-9]/g, '')
  }
}

export const returnDeclinationReason = propery => {
  switch (propery) {
    case 'haveCcFailure':
      return 'have Cc Failure'
    default:
      return propery
  }
}

export const generalPhoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const POBOX_REGEX = /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i

export const getEstimatedMilesOptions = (affilateCode, isAdmin) => {
  if (affilateCode == 'MARKEL') {
    return {
      estimatedMileageOption: isAdmin
        ? [500].concat(estimatedMileageOption)
        : estimatedMileageOption.filter(m => !estimatedNotAllowedForMarkelAffiliate.includes(m)),
      estimatedMileageSteperMarks: isAdmin
        ? [{ value: '500' }].concat(estimatedMileageSteperMarks)
        : estimatedMileageSteperMarks.filter(mark => !estimatedNotAllowedForMarkelAffiliate.includes(parseInt(mark.value))),
    }
  }
  return {
    estimatedMileageOption: estimatedMileageOption,
    estimatedMileageSteperMarks: estimatedMileageSteperMarks,
  }
}

export const debounce = (func, time) => {
  let timer
  return (...args) => {
    const context = this
    if (timer) clearTimeout(timer)
    timer = setTimeout(async () => {
      timer = null
      await func(...args)
    }, time)
  }
}
